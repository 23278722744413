import SharedStyles from "@components/Dashboard/Pro/Shared/Background/@styles/Background.styles";

export default (theme) => {
	const sharedStyles = SharedStyles(theme);
	return Object.assign(sharedStyles, {
		toolbarSpacing: {
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			padding: theme.spacing(0, 2),
			// necessary for content to be below app bar
			minHeight: 72,
			[theme.breakpoints.down("xs")]: {
				minHeight: 63,
			},
		},
		printHidden: {
			"@media print": {
				visibility: "hidden",
				display: "none",
			},
		},
	});
};
