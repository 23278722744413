import { observable, action, runInAction, toJS, computed } from "mobx";
import { getParameterByName, replaceUrlParam } from "@utils/Browser";
import required from "@libs/required";
import rootService from "../@data/service";

const { getService } = rootService;

class HeaderSearchBarStore {
	@observable _service;
	@observable drawerDisplay;
	zipcode;

	constructor(rootStore, staticContext) {
		this.rootStore = rootStore;
		this.staticContext = staticContext;
		this.zipcode =
			getParameterByName("zipcode") ||
			(staticContext.app.location || {}).zipcode;
	}

	@computed
	get service() {
		return toJS(this._service);
	}

	@action
	async init() {
		try {
			if (this.staticContext.service && !this._service) {
				this._service = this.staticContext.service;
				return;
			}

			let serviceKey = getParameterByName("midtail");
			if (!serviceKey) serviceKey = getParameterByName("service");
			if (!serviceKey) return;

			const service = await getService(
				{ filter: { key: serviceKey } },
				{ batch: true }
			);
			runInAction(() => {
				this._service = service;
			});
		} catch (err) {
			throw err;
		}
	}

	@action
	updateService(
		service = required`service`,
		history,
		shouldRoute = true,
		routeTo
	) {
		this._service = service;
		if (this.zipcode && history && shouldRoute) this.route(history, routeTo);
	}

	toggleDrawer(value) {
		this.drawerDisplay = value;
	}

	updateZipcode(value) {
		this.zipcode = value;
	}

	zipCodeValidation(value) {
		if (!value) return false;
		return value.length === 5;
	}

	route(history) {
		if (!this.service) return;

		if (this.zipCodeValidation(this.zipcode) === false) {
			throw new Error("Please enter a valid zipcode");
		}

		let routeTo = "/search";
		if (this.service.search_to_map) routeTo = "/search-map";

		let url = `${routeTo}${window.location.search}`;
		url = replaceUrlParam(url, "service", "");
		url = replaceUrlParam(url, "ne", "");
		url = replaceUrlParam(url, "sw", "");
		url = replaceUrlParam(url, "midtail", "");

		url = replaceUrlParam(
			url,
			this.service.midtail ? "midtail" : "service",
			this.service.key
		);

		url = replaceUrlParam(url, "leadKey", "");
		window.location.href = replaceUrlParam(url, "zipcode", this.zipcode);

		//history.push(replaceUrlParam(url, "zipcode", this.zipcode));
	}
}

export default HeaderSearchBarStore;
