import gql from "graphql-tag";

export const serviceQueryFragment = gql`
	fragment serviceQueryFragment on Service {
		key
		name
		midtail
		name_proper
		search_to_map
	}
`;
