export default (theme) => ({
	root: {
		padding: "0px 6px 0px 16px",
		display: "flex",
		alignItems: "center",
		maxWidth: 500,
	},
	searchBorder: {
		"border": "1px solid #DDD",
		"boxShadow": "0px 1px 2px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 5%)",
		"&:hover": {
			boxShadow: "0px 2px 4px rgb(0 0 0 / 18%)",
		},
		"&:focus": {
			boxShadow: "0px 2px 4px rgb(0 0 0 / 18%)",
		},
		"&:active": {
			boxShadow: "0px 2px 4px rgb(0 0 0 / 18%)",
		},
		"borderRadius": 24,
	},
	searchBorderGray: {
		backgroundColor: "#F7F7F7",
		borderRadius: 24,
	},
	searchButtonMobile: {
		fontSize: 14,
		width: "100%",
		padding: "8px 16px",
	},
	label: {
		justifyContent: "flex-start",
	},
	input: {
		width: "100%",
		fontWeight: 600,
		//flex: "1 1 0%",
		//padding: "6px 0px !important",
	},
	input2: {
		padding: "14px 0px !important",
	},
	inputRoot: {
		padding: "0px 2px !important",
	},
	zipInputBase: {
		padding: "14px 0px !important",
	},
	inputZip: {
		fontWeight: 600,
		flex: "0 0 108px",
	},
	iconButton: {
		"padding": 6,
		"color": "#fff",
		"backgroundColor": theme.palette.primary.main,
		"&:hover, &.Mui-focusVisible": { backgroundColor: "rgb(18, 80, 162)" },
	},
	inputZipIcon: {
		padding: 5,
	},
	iconZip: {
		color: "rgba(0, 0, 0, 0.40) !important",
	},
	iconZipMobile: {
		color: theme.palette.primary.main,
	},
	inputTruncate: {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	divider: {
		height: 28,
		margin: 4,
	},
	closeButton: {
		//position: "absolute"
	},
	inputFieldMobile: {
		fontWeight: 600,
	},
});
