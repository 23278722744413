import React, { useEffect } from "react";
import { compose } from "recompose";
import { observer } from "mobx-react";

import withStores from "@framework/src/libs/withStores";
import withStyles from "@framework/src/libs/withStyles";

import { useHistory } from "react-router";
import useError from "@hooks/useError";
import useStores from "@hooks/useStores";

import Header from "../Header";

import HeaderSearchBarMobile from "./HeaderSearchBarMobile";
import HeaderSearchBarDesktop from "./HeaderSearchBarDesktop";

import { stores, styles } from "./.config";

const HeaderSearchBar = compose(
	withStyles(styles),
	withStores(stores),
	observer
)(({ fixed, mobileOnly, mobileOpen }) => {
	const history = useHistory();

	const [ErrorTemplate, onError] = useError();
	const { headerSearchBarStore: store } = useStores();

	const zipcodeOnChangeHandler = (e) => store.updateZipcode(e.target.value);
	const serviceOnSelectHandler = (service, shouldRoute) =>
		store.updateService(service, history, shouldRoute);
	const serviceOnChangeHandler = (service, shouldRoute) =>
		store.updateService(service, undefined, shouldRoute);
	const onSubmitHandler = (e) => {
		try {
			e.preventDefault();
			store.route(history);
		} catch (err) {
			onError(err);
		}
	};

	useEffect(() => {
		store.init().then().catch(onError);
	}, []);

	return (
		<Header fixed={fixed}>
			{!mobileOnly && (
				<HeaderSearchBarDesktop
					zipcodeOnChangeHandler={zipcodeOnChangeHandler}
					serviceOnSelectHandler={serviceOnSelectHandler}
					serviceOnChangeHandler={serviceOnChangeHandler}
					onSubmitHandler={onSubmitHandler}
				/>
			)}
			<HeaderSearchBarMobile
				open={mobileOpen}
				zipcodeOnChangeHandler={zipcodeOnChangeHandler}
				serviceOnSelectHandler={serviceOnSelectHandler}
				serviceOnChangeHandler={serviceOnChangeHandler}
				onSubmitHandler={onSubmitHandler}
			/>
			<ErrorTemplate />
		</Header>
	);
});

HeaderSearchBar.defaultProps = {
	routeTo: "/search",
};

HeaderSearchBar.propTypes = {};

export default HeaderSearchBar;
