import gql from "graphql-tag";

export const searchServiceAutoCompleteQueryFragment = gql`
	fragment searchServiceAutoCompleteQueryFragment on Service {
		_id
		key
		name_proper
		name
		midtail
		search_to_map
	}
`;
