import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import useStyles from "@hooks/useStyles";
import useStores from "@hooks/useStores";

import AutoComplete from "@components/Global/Custom/Autocomplete";
import ZipcodeTextField from "@components/Global/Custom/TextField/types/ZipcodeTextField";

import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";

const HeaderSearchBarDesktop = observer(
	({
		zipcodeOnChangeHandler,
		serviceOnSelectHandler,
		serviceOnChangeHandler,
		onSubmitHandler,
	}) => {
		const classes = useStyles("HeaderSearchBar");
		const { headerSearchBarStore: store } = useStores();

		return (
			<Box
				component={"div"}
				display={{ xs: "none", sm: "block" }}
				width={"100%"}
			>
				<Paper
					component="form"
					className={`${classes.root} ${classes.searchBorder}`}
					autoComplete="on"
				>
					<AutoComplete
						useDefaultStartAdornment={false}
						autoHighlight
						id={"searchServiceInput"}
						defaultValue={
							store.service
								? store.service.name_proper || store.service.name
								: ""
						}
						className={classes.input}
						classes={{ input: classes.input2, inputRoot: classes.inputRoot }}
						TextFieldProps={{
							disableErrorText: true,
							placeholder: "What’s on your to-do list?",
							name: "service",
							InputProps: {
								disableUnderline: true,
								style: {
									fontWeight: "600",
								},
							},
							inputProps: {
								"aria-label": "What’s on your to-do list?",
								"className": classes.input,
								"autoCorrect": "off",
								"spellCheck": "false",
							},
						}}
						onSelect={serviceOnSelectHandler}
						onChange={serviceOnChangeHandler}
						searchOptions={{ active: true }}
					/>
					<Divider className={classes.divider} orientation="vertical" />

					<ZipcodeTextField
						disableErrorText
						variant={"standard"}
						onChange={zipcodeOnChangeHandler}
						defaultValue={store.zipcode}
						id={"searchZipcodeInput"}
						name={"zip"}
						InputProps={{
							style: {
								fontWeight: "600",
							},
							classes: { root: classes.iconZip, input: classes.zipInputBase },
							startAdornment: (
								<Box
									className={classes.inputZipIcon}
									display={"flex"}
									alignItems={"center"}
								>
									<RoomOutlinedIcon />
								</Box>
							),
							className: classes.zipInput,
						}}
					/>

					<IconButton
						color="primary"
						size={"small"}
						aria-label="Search"
						name={"submit"}
						id={"submit"}
						className={classes.iconButton}
						type={"submit"}
						onClick={onSubmitHandler}
					>
						<SearchIcon />
					</IconButton>
				</Paper>
			</Box>
		);
	}
);

HeaderSearchBarDesktop.propTypes = {
	zipcodeOnChangeHandler: PropTypes.func.isRequired,
	serviceOnSelectHandler: PropTypes.func.isRequired,
	serviceOnChangeHandler: PropTypes.func.isRequired,
	onSubmitHandler: PropTypes.func.isRequired,
};

export default HeaderSearchBarDesktop;
