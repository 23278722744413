import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { compose } from "recompose";
import clsx from "clsx";
import { useLocation } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
// import Container from "@material-ui/core/Container";

import useStyles from "@hooks/useStyles";
import useError from "@hooks/useError";

import withConstants from "@framework/src/libs/withConstants";
import withStyles from "@framework/src/libs/withStyles";
import withStores from "@libs/framework/src/libs/withStores";

import PropTypes from "prop-types";

import { styles, constants, stores } from "./.config";
import useStores from "@libs/framework/src/hooks/useStores";

import { getParameterByName } from "@utils/Browser";
import useSuccess from "@hooks/useSuccess";

import BackgroundNavManagement from "./BackgroundNavManagement";
import HeaderSearchBar from "@components/Main/Shared/HeaderSearchBar";
import BackgroundNavTabs from "./BackgroundNavTabs";

const { ENV } = process.env;

const Background = compose(
	withStyles(styles),
	//withConstants(constants),
	//withStores(stores, { setGlobal: true }),
	observer
)(
	({
		children,
		drawerHidden,
		toggleNotification,
		preventClaimProfileDialog,
		tabs,
	}) => {
		const classes = useStyles("Background");
		const anchor = "left";

		return (
			<React.Fragment>
				<div className={classes.root}>
					<div className={classes.printHidden}>
						<HeaderSearchBar fixed />
					</div>
					<Box
						display={{ xs: "block", sm: "block", md: "none" }}
						className={classes.printHidden}
					>
						<Drawer
							className={classes.drawer}
							classes={{ paper: classes.drawer }}
							anchor={anchor}
							open={false}
							//onClose={handleToggleNav(anchor)}
						>
							{/* {(ENV === "stage-OFF" || ENV === "production") && (
								<BackgroundNavManagement open />
							)}
							{(ENV === "stage" || ENV === "development") && (
								<BackgroundNavManagement open />
							)} */}
						</Drawer>
					</Box>
					<Box //</div>display={{ xs: "none", sm: "none", md: "block" }}
						display={
							ENV === "stage" || ENV === "development"
								? { xs: "none", sm: "none", md: "block" }
								: { xs: "none", sm: "none", md: "none" }
						}
					>
						<Drawer
							variant="permanent"
							className={classes.drawerOpen}
							// className={clsx(classes.drawer, {
							// 	[classes.drawerOpen]: store.drawerState,
							// 	[classes.drawerClose]: !store.drawerState,
							// 	[classes.drawerHidden]: drawerHidden && !store.drawerState,
							// })}
							classes={{ paper: classes.drawerOpen }}
							// classes={{
							// 	paper: clsx({
							// 		[classes.drawerOpen]: store.drawerState,
							// 		[classes.drawerClose]: !store.drawerState,
							// 		[classes.drawerHidden]: drawerHidden && !store.drawerState,
							// 	}),
							// }}
						>
							<div className={classes.toolbarSpacing} />
							<Divider light />

							{(ENV === "stage-OFF" || ENV === "production") && (
								<BackgroundNavManagement open />
							)}
							{(ENV === "stage" || ENV === "development") && (
								<BackgroundNavManagement open />
							)}
						</Drawer>
					</Box>
					<Box
						component={"main"}
						position={"relative"}
						className={
							(ENV === "stage" || ENV === "development") && classes.content
						}

						// className={clsx({
						// 	[classes.content]: store.drawerState,
						// 	[classes.contentClose]: !store.drawerState,
						// })}
					>
						{/* <div className={classes.toolbarSpacing} /> */}
						{children}
						<div className={classes.printHidden}>
							{(ENV === "stage" || ENV === "development") && (
								<>{tabs && <BackgroundNavTabs />}</>
							)}
						</div>
					</Box>
					{/* <ErrorTemplate />
					<SuccessTemplate /> */}
				</div>
			</React.Fragment>
		);
	}
);

Background.defaultProps = {
	drawerHidden: false,
	tabs: true,
};

Background.propTypes = {
	drawerHidden: PropTypes.bool,
	tabs: PropTypes.bool,
};

export default Background;
