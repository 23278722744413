import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useStyles from "@hooks/useStyles";
import useStores from "@hooks/useStores";
import { useLocation, useParams } from "react-router-dom";
import withConstants from "@framework/src/libs/withConstants";
import useConstants from "@hooks/useConstants";

import Box from "@material-ui/core/Box";
import { compose } from "recompose";
import withStyles from "@framework/src/libs/withStyles";
import withStores from "@framework/src/libs/withStores";
import List from "@material-ui/core/List";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import clsx from "clsx";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";

import HomeIcon from "@components/Global/Custom/Icons/HomeIcon";
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import LoyaltyOutlinedIcon from "@material-ui/icons/LoyaltyOutlined";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import SupervisedUserCircleOutlinedIcon from "@material-ui/icons/SupervisedUserCircleOutlined";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import GradeOutlinedIcon from "@material-ui/icons/GradeOutlined";

import RouterComponent from "./RouterComponent";

import { styles, constants, stores } from "./.config";
import { observer } from "mobx-react";

const { LINK_HELP } = process.env;

import { adjective as proAdjective } from "@language/provider";

const BackgroundNavManagement = compose(
	withStyles(styles),
	//withStores(stores),
	//withConstants(constants),
	observer
)(({ open }) => {
	const classes = useStyles("Background");
	//const { backgroundStore: store, accountStore } = useStores();
	//	const siteConfigs = useConstants("BackgroundViewModel");
	//	const { showWhiteboard, saas, referral } = siteConfigs;
	const location = useLocation();
	const params = useParams();

	// State for managing submenu expansion
	const [expandedSubmenus, setExpandedSubmenus] = useState({});

	const topNavItems = [
		{
			url: "/client",
			title: "Home",
			icon: <HomeIcon />,
			isManagment: true,
			subRoutes: [],
			siblingRoutes: [],
			subItems: [],
		},
		// {
		// 	url: "/client/calendar",
		// 	title: "Calendar",
		// 	icon: <DateRangeOutlinedIcon />,
		// 	isManagment: true,
		// 	subRoutes: [],
		// 	siblingRoutes: [],
		// 	subItems: [],
		// },
		{
			url: "/client/members",
			title: "My members",
			icon: <SupervisedUserCircleOutlinedIcon />,
			isManagment: true,
			subRoutes: [],
			siblingRoutes: [],
			subItems: [],
		},
		{
			url: "/client/projects",
			title: "Requests",
			icon: <ListAltOutlinedIcon />,
			isManagment: true,
			subRoutes: [],
			siblingRoutes: [],
			subItems: [],
		},
		{
			url: "/client/bookings",
			title: "Bookings",
			icon: <DateRangeOutlinedIcon />,
			isManagment: true,
			subRoutes: [],
			siblingRoutes: [],
			subItems: [],
		},

		{
			url: "/client/memberships",
			title: "Memberships",
			icon: <LoyaltyOutlinedIcon />,
			isManagment: true,
			subRoutes: [],
			siblingRoutes: [],
			subItems: [],
		},
		{
			url: "/client/inbox",
			title: "Messages",
			icon: <SmsOutlinedIcon />,
			isManagment: true,
			subRoutes: [],
			siblingRoutes: [],
			subItems: [],
		},
		{
			url: "/client/team",
			title: `My ${proAdjective.plural}`,
			icon: <GroupOutlinedIcon />,
			isManagment: true,
			subRoutes: [],
			siblingRoutes: [],
			subItems: [],
		},
		{
			url: "/client/payments",
			title: "Payments",
			icon: <CreditCardOutlinedIcon />,
			isManagment: true,
			subRoutes: [],
			siblingRoutes: [],
			subItems: [
				{
					url: "/client/payments/settings",
					title: "Settings",
					icon: <SettingsOutlinedIcon />,
				},
			],
		},

		{
			url: "/client/reviews",
			title: `Reviews`,
			icon: <GradeOutlinedIcon />,
			isManagment: true,
			subRoutes: [],
			siblingRoutes: [],
			subItems: [],
		},

		{
			url: "/client/account",
			title: "My profile",
			icon: <AccountCircleOutlinedIcon />,
			isManagment: true,
			subRoutes: [],
			siblingRoutes: [],

			subItems: [],
		},
		{
			url: { pathname: LINK_HELP },
			title: "Help",
			subRoutes: [],
			siblingRoutes: [],
			icon: <HelpOutlineIcon />,
			isManagment: false,
			target: "_blank",
		},
	];

	const mobileNavItems = [];

	// Automatically expand submenu for the current main item
	useEffect(() => {
		const currentPath = location.pathname;
		const foundIndex = topNavItems.findIndex(
			(item) =>
				item.url === currentPath ||
				(item.subItems &&
					item.subItems.some((subItem) => subItem.url === currentPath))
		);

		if (foundIndex !== -1) {
			const submenuKey = `submenu-${foundIndex}`;
			// Check if the submenu is already expanded
			setExpandedSubmenus((prevExpandedSubmenus) => {
				// If the submenu is already expanded, do not update the state
				if (prevExpandedSubmenus[submenuKey]) {
					return prevExpandedSubmenus;
				}
				// Otherwise, update the state to expand the submenu
				return {
					...prevExpandedSubmenus,
					[submenuKey]: true,
				};
			});
		}
		// Make sure to include dependencies that change when needed but do not cause infinite loops
	}, [location.pathname]); // Only re-run the effect if the pathname changes

	const handleSubmenuToggle = (submenu) => {
		setExpandedSubmenus((prevExpandedSubmenus) => ({
			...prevExpandedSubmenus,
			[submenu]: !prevExpandedSubmenus[submenu],
		}));
	};

	const renderSubItems = (subItems, submenuKey) => (
		<Collapse in={expandedSubmenus[submenuKey]} timeout="auto" unmountOnExit>
			<List component="div" disablePadding>
				{subItems.map((subItem) => {
					const isSelected = subItem.url === location.pathname;

					return (
						<>
							<Tooltip
								key={subItem.title}
								enterDelay={400}
								title={subItem.title}
								arrow
								TransitionComponent={Zoom}
								placement={"right"}
								classes={{ tooltip: clsx({ [classes.adminToolTips]: open }) }}
							>
								<ListItem
									button
									component="a"
									href={subItem.url}
									selected={isSelected}
									classes={{
										root: classes.listPanelItemNested,
										button: clsx({
											[classes.adminListPanelButton]: open,
											[classes.adminListPanelItemActive]: isSelected,
											[classes.adminListPanelItem]: !isSelected,
										}),
									}}
								>
									<ListItemIcon
										className={
											isSelected
												? classes.adminListItemIconActive
												: classes.adminListItemIcon
										}
									>
										{subItem.icon}
									</ListItemIcon>
									<ListItemText
										className={
											isSelected
												? classes.adminListItemTextActive
												: classes.adminListItemTextNested
										}
										disableTypography
										primary={subItem.title}
									/>
									{!!subItem.appendEnd && subItem.appendEnd}
								</ListItem>
							</Tooltip>
							{subItem.hasDivider && (
								<>
									<Box py={3}>
										<Divider />
									</Box>
									<ListItem disabled className={classes.listPrimaryDisabled}>
										Options
									</ListItem>
								</>
							)}
						</>
					);
				})}
			</List>
		</Collapse>
	);

	const renderNavItems = (items) =>
		items.map((item, index) => {
			const isSelected = [item.url, ...item.subRoutes].includes(
				location.pathname
			);
			const isWithinSPA = [
				item.url,
				...item.subRoutes,
				...item.siblingRoutes,
			].includes(location.pathname);
			const hasSubItems = item.subItems && item.subItems.length > 0;
			const submenuKey = `submenu-${index}`;

			return (
				<React.Fragment key={item.title}>
					<RouterComponent
						isWithinSPA={true}
						url={item.url}
						target={item.target}
					>
						<Tooltip
							enterDelay={400}
							title={item.title}
							arrow
							TransitionComponent={Zoom}
							placement={"right"}
							classes={{ tooltip: clsx({ [classes.adminToolTips]: open }) }}
						>
							<ListItem
								button
								selected={isSelected}
								onClick={() => hasSubItems && handleSubmenuToggle(submenuKey)}
								classes={{
									root: classes.listPanelItem,
									button: clsx({
										[classes.adminListPanelButton]: open,
									}),
									selected: isSelected
										? classes.adminListPanelItemActive
										: classes.adminListPanelItem,
								}}
							>
								<ListItemIcon
									className={
										isSelected
											? classes.adminListItemIconActive
											: classes.adminListItemIcon
									}
								>
									{item.icon}
								</ListItemIcon>
								<ListItemText
									className={
										isSelected
											? classes.adminListItemTextActive
											: classes.adminListItemText
									}
									disableTypography
									primary={item.title}
								/>
								{!!item.appendEnd && item.appendEnd}
							</ListItem>
						</Tooltip>
					</RouterComponent>
					{hasSubItems && renderSubItems(item.subItems, submenuKey)}
				</React.Fragment>
			);
		});

	return (
		<React.Fragment>
			<>
				<List
					classes={{
						root: clsx({
							[classes.adminList]: open,
						}),
					}}
				>
					{renderNavItems(topNavItems)}

					<Box display={{ xs: "block", sm: "block", md: "none" }}>
						{mobileNavItems.length > 0 && (
							<>
								<Divider light />
								{renderNavItems(mobileNavItems)}
								<Divider light />
							</>
						)}

						<Tooltip
							enterDelay={400}
							title="Sign out"
							arrow
							TransitionComponent={Zoom}
							placement={"right"}
							classes={{
								tooltip: clsx({
									[classes.adminToolTips]: open,
								}),
							}}
						>
							<ListItem button key={"Sign out"}>
								<ListItemIcon className={classes.adminListItemIcon}>
									<ExitToAppIcon />
								</ListItemIcon>

								<ListItemText
									className={classes.adminListItemText}
									disableTypography
									primary={"Sign out"}
								/>
							</ListItem>
						</Tooltip>
					</Box>
				</List>
			</>
		</React.Fragment>
	);
});

BackgroundNavManagement.propTypes = {
	open: PropTypes.bool,
};

export default BackgroundNavManagement;
