import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { compose } from "recompose";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import AutoComplete from "@components/Global/Custom/Autocomplete";
import { ZipcodeTextField } from "@components/Global/Custom/TextField";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import useStyles from "@hooks/useStyles";
import useStores from "@hooks/useStores";
import useError from "@hooks/useError";

import withStores from "@framework/src/libs/withStores";
import withStyles from "@framework/src/libs/withStyles";
import { stores, styles } from "./.config";

const HeaderSearchDrawer = compose(
	withStyles(styles),
	withStores(stores),
	observer
)(({ anchor, service, open, onToggle }) => {
	const classes = useStyles("HeaderSearchDrawer");
	const { mobileDrawerStore, headerSearchDrawerStore: store } = useStores();
	const [ErrorTemplate, onError] = useError();
	const toggleDrawer = (anchor, open) => (event) => {
		if (
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}
		onToggle(open);
		mobileDrawerStore.update(anchor, open); //technically not needed but idk if removing this will have unintended consequences
	};

	const zipcodeOnChangeHandler = (e) => store.updateZipcode(e.target.value);
	const serviceOnSelectHandler = (service, shouldRoute) =>
		store.updateService(service, history, shouldRoute);
	const serviceOnChangeHandler = (service, shouldRoute) =>
		store.updateService(service, undefined, shouldRoute);
	const onSubmitHandler = (e) => {
		try {
			e.preventDefault();
			store.route(history);
		} catch (err) {
			onError(err);
		}
	};

	useEffect(() => {
		store.init().then().catch(onError);
	}, []);

	useEffect(() => {
		if (service) {
			store.updateService(service, undefined);
		}
		mobileDrawerStore.update(anchor, !!open);
	}, [open, service]);

	return (
		<Drawer
			data-testid="header-search-drawer"
			anchor={anchor}
			open={mobileDrawerStore.isOpen(anchor)}
			onClose={toggleDrawer(anchor, false)}
		>
			<Box component={"div"} p={5}>
				<Box component={"div"} width={"100%"}>
					<Box component={"form"} autoComplete="on">
						<Grid container spacing={4}>
							<Grid item xs={12}>
								<Box
									component={"div"}
									display={"flex"}
									alignItems={"center"}
									justifyContent={"space-between"}
								>
									<Box fontSize={16} style={{ fontWeight: 600 }}>
										{store.service ? "Edit" : "Start"} your search
									</Box>

									<IconButton
										aria-label="close"
										className={classes.closeButton}
										onClick={toggleDrawer(anchor, false)}
										edge={"end"}
									>
										<CloseIcon />
									</IconButton>
								</Box>
							</Grid>

							<Grid item xs={12}>
								<AutoComplete
									id="service-change"
									autoHighlight
									onSelect={(service) => serviceOnSelectHandler(service, false)}
									onChange={(service) => serviceOnChangeHandler(service, false)}
									defaultValue={
										store.service
											? (
													store.service.name_proper || store.service.name
											  ).ucFirst()
											: ""
									}
									searchOptions={{ active: true }}
									TextFieldProps={{
										fullWidth: true,
										disableErrorText: true,
										disableOnBlurValidation: true,
										variant: "outlined",
										placeholder: "What’s on your to-do list?",
										InputProps: {
											classes: { root: classes.iconZip },
											className: classes.inputFieldMobile,
											startAdornment: (
												<InputAdornment position="start">
													<SearchIcon />
												</InputAdornment>
											),
										},
									}}
								/>
							</Grid>

							<Grid item xs={12}>
								<ZipcodeTextField
									fullWidth
									variant="outlined"
									placeholder="Zip code"
									onChange={zipcodeOnChangeHandler}
									defaultValue={store.zipcode}
									id="zip-change"
									InputProps={{
										classes: { root: classes.iconZip },
										className: classes.inputFieldMobile,
										startAdornment: (
											<InputAdornment position="start">
												<RoomOutlinedIcon />
											</InputAdornment>
										),
									}}
								/>
							</Grid>

							<Grid item xs={6}>
								{/* <Button
											color="primary"
											onClick={toggleDrawer(anchor, false)}
										>
											Cancel
										</Button> */}
							</Grid>
							<Grid item xs={6} style={{ textAlign: "end" }}>
								<Button
									variant="contained"
									disableElevation
									color="primary"
									aria-label="Search"
									name={"submit"}
									type={"submit"}
									id={"submit"}
									size={"large"}
									startIcon={<SearchIcon />}
									onClick={(e) => {
										toggleDrawer(anchor, false)(e);
										onSubmitHandler(e);
									}}
								>
									Search
								</Button>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Box>
			<ErrorTemplate />
		</Drawer>
	);
});

HeaderSearchDrawer.defaultProps = {
	anchor: "top",
	open: false,
	onToggle: () => {},
};

HeaderSearchDrawer.propTypes = {
	onToggle: PropTypes.func,
	open: PropTypes.bool,
};

export default HeaderSearchDrawer;
