import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

// Hooks
import useError from "@hooks/useError";
import withStyles from "@libs/framework/src/libs/withStyles";

// MUI
import MaterialAutocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@components/Global/Custom/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

// MUI Icons
import SearchIcon from "@material-ui/icons/Search";

import rootService from "./@data/service";

// Config
import styles from "@config/styles";

// Services
const { searchServiceAutoComplete } = rootService;

const AutoComplete = compose(withStyles(styles))(
	({
		id,
		TextFieldProps,
		defaultValue,
		onSelect,
		useDefaultStartAdornment,
		className,
		errorHelperText,
		onChange,
		searchOptions,
		...props
	}) => {
		const [ErrorTemplate, onError] = useError();
		const [services, setServices] = useState([]);
		const [value, setValue] = useState(defaultValue);
		const [fetching, setFetching] = useState(false);

		useEffect(() => {
			if (value !== defaultValue) {
				setValue(defaultValue);
			}
		}, [defaultValue]);

		useEffect(() => {
			if (TextFieldProps?.autoFocus) onInputFocus().then().catch(onError);
		}, []);

		if (!TextFieldProps.InputProps) TextFieldProps.InputProps = {};
		const DefaultStartAdornment = (
			<InputAdornment position="start">
				<SearchIcon />
			</InputAdornment>
		);

		if (!TextFieldProps.errorHelperText)
			TextFieldProps.errorHelperText = errorHelperText;
		if (useDefaultStartAdornment)
			Object.assign(TextFieldProps.InputProps, {
				startAdornment: DefaultStartAdornment,
			});

		const onSuggestionsFetchRequested = async ({ value }) => {
			setFetching(true);

			await searchServiceAutoComplete({
				prefix: value,
				size: 6,
				...searchOptions,
			})
				.then((response) => {
					setServices(response);
					setFetching(false);
				})
				.catch((err) => {
					setFetching(false);
					console.log(err);
				});
		};

		const onSuggestionChange = (e, service, reason) => {
			onChange(service);
			if (reason === "select-option") onSelect(service);
		};

		const onInputChange = (e, value, reason) => {
			if (reason === "reset" && !value) return;
			if (e) setValue(value);

			onSuggestionsFetchRequested({ value });
		};

		const onInputFocus = async () => {
			if (!services?.length) setFetching(true);
			await searchServiceAutoComplete({
				prefix: value,
				size: 15,
				...searchOptions,
			})
				.then((response) => {
					setServices(response);
					setFetching(false);
				})
				.catch((err) => {
					setFetching(false);
					console.log(err);
				});
		};

		/* const onHighlightChange = (e, service) => service && setValue(service.name); */
		const getOptionLabel = (service) =>
			service && (service.name_proper || service.name);
		const getOptionSelected = (option, value) =>
			option && value && option.name === value.name;

		const renderOptionHighlight = (option, { inputValue }) => {
			const optionName = option.name_proper || option.name;
			const matches = match(optionName, inputValue);
			const parts = parse(optionName, matches);

			return (
				<div>
					{parts.map((part, index) => (
						<span
							key={index}
							style={{ fontWeight: part.highlight ? 700 : 400 }}
						>
							{part.text}
						</span>
					))}
				</div>
			);
		};

		return (
			<React.Fragment>
				<MaterialAutocomplete
					id={id}
					className={className}
					inputValue={value}
					key={`custom-autocomplete-${id || "0"}`}
					options={services}
					forcePopupIcon={false}
					loading={fetching}
					filterOptions={(options) => options}
					onChange={onSuggestionChange}
					onInputChange={onInputChange}
					getOptionSelected={getOptionSelected}
					getOptionLabel={getOptionLabel}
					renderInput={(params) => {
						Object.assign(params.InputProps, TextFieldProps.InputProps || {});

						if (
							TextFieldProps.InputProps &&
							TextFieldProps.InputProps.inputProps &&
							TextFieldProps.InputProps.inputProps.className
						) {
							const className = `${TextFieldProps.InputProps.inputProps.className} ${params.inputProps.className}`;
							Object.assign(
								params.inputProps,
								TextFieldProps.InputProps.inputProps,
								{ className }
							);
						} else
							Object.assign(
								params.inputProps,
								TextFieldProps.InputProps.inputProps
							);
						return (
							<TextField
								{...TextFieldProps}
								{...params}
								onFocus={onInputFocus}
							/>
						);
					}}
					renderOption={renderOptionHighlight}
					{...props}
				/>
				<ErrorTemplate />
			</React.Fragment>
		);
	}
);

AutoComplete.defaultProps = {
	onChange: () => {},
	defaultValue: "",
	TextFieldProps: {},
	errorHelperText: "Please choose a service",
	useDefaultStartAdornment: true,
};

AutoComplete.propTypes = {
	onSelect: PropTypes.func.isRequired,
	className: PropTypes.string,
	useDefaultStartAdornment: PropTypes.bool,
	TextFieldProps: PropTypes.shape({
		errorHelperText: PropTypes.string,
		variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
		...TextField.propTypes,
	}),
	onChange: PropTypes.func,
	defaultValue: PropTypes.string,
	searchOptions: PropTypes.shape({
		active: PropTypes.bool,
		midtail: PropTypes.bool,
	}),
};

export default AutoComplete;
