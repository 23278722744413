import React, { forwardRef, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { compose } from "recompose";
import shortid from "shortid";
import clsx from "clsx";

import withStores from "@framework/src/libs/withStores";
import withStyles from "@framework/src/libs/withStyles";

import useStores from "@hooks/useStores";
import useStyles from "@hooks/useStyles";

import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";

import { stores, styles } from "../.config";
import unsupported from "../@libs/unsupported";

export const ZipcodeTextField = compose(
	withStores(stores),
	withStyles(styles),
	observer,
	forwardRef
)(
	(
		{
			id,
			groupId,
			type,
			value,
			defaultValue,
			onValidation,
			error,
			useDefaultStartAdornment,
			required: isRequired,
			disableErrorText,
			helperText,
			inputProps,
			InputProps,
			onBlur,
			variant,
			onChange,
			inputRef,
			errorHelperText,
			validation,
			disableOnBlurValidation,
			...props
		},
		ref
	) => {
		const [_id] = useState(id || shortid.generate());
		const classes = useStyles("TextField");

		const { textFieldStore: store } = useStores();
		const instance = store.findInstanceById(_id);
		const _helperText =
			store.showError(_id) && !disableErrorText
				? errorHelperText || store.errorMessage(_id)
				: helperText;

		if (!inputRef) inputRef = useRef();
		const InputPropsClasses = Object.assign({}, InputProps.classes || {});

		useEffect(() => {
			store.init({ type, groupId, id: _id, ref: inputRef, validation });
			if (value || defaultValue) store.isValid(_id);
			return () => store.removeInstanceById(_id);
		}, []);

		const DefaultStartAdornment = (
			<InputAdornment position="start">
				<LocationOnOutlinedIcon />
			</InputAdornment>
		);

		Object.assign(
			InputPropsClasses,
			variant === "standard"
				? { root: clsx([InputPropsClasses.root, classes.root]) }
				: {},
			variant !== "standard"
				? {
						adornedStart: clsx([
							InputPropsClasses.adornedStart,
							classes.adornedStart,
						]),
				  }
				: {},
			variant !== "standard"
				? {
						adornedEnd: clsx([
							InputPropsClasses.adornedEnd,
							classes.adornedEnd,
						]),
				  }
				: {}
		);

		const onChangeHandler = (e) => {
			const isValid = instance.validate(e.target.value);
			if (e.target.value.length > 0 && e.target.value.length < 5) {
				store.displayError(_id, false);
				onValidation(isValid);
				onChange(e);

				return;
			}

			if (isValid === false) {
				store.isValid(_id);
				instance.displayError(true);
			}

			onValidation(isValid);
			onChange(e);
		};

		useEffect(() => {
			if (!instance) return;
			if (error === true && instance.showError === false)
				instance.displayError(true);
			if (error === false && instance.showError === true)
				instance.displayError(false);
			instance.isRequired = isRequired;
		});

		const onBlurHandler = (e) => {
			if (disableOnBlurValidation === false) onValidation(store.isValid(_id));
			onBlur(e);
		};

		return (
			<TextField
				ref={ref}
				inputRef={inputRef}
				onBlur={onBlurHandler}
				error={error || store.showError(_id)}
				helperText={_helperText}
				variant={variant}
				value={value}
				defaultValue={defaultValue}
				onChange={onChangeHandler}
				autoCorrect="off"
				id={_id}
				type="number"
				autoComplete="postal-code"
				inputProps={Object.assign(
					{
						"autoCorrect": "off",
						"spellCheck": "false",
						"pattern": "[0-9]*",
						"maxLength": "5",
						"inputMode": "numeric",
						"aria-invalid": "false",
					},
					inputProps
				)}
				InputProps={Object.assign(
					variant === "outlined" ? {} : { disableUnderline: true },
					InputProps,
					{ classes: InputPropsClasses },
					useDefaultStartAdornment && !InputProps.startAdornment
						? { startAdornment: DefaultStartAdornment }
						: {}
				)}
				{...props}
			/>
		);
	}
);

ZipcodeTextField.defaultProps = {
	"type": "zipcode",
	"onValidation": () => {},
	"onChange": () => {},
	"onBlur": () => {},
	"required": true,
	"aria-label": "Enter location",
	"placeholder": "Zip code",
	"helperText": "",
	"errorHelperText": "",
	"variant": "standard",
	"inputProps": {},
	"InputProps": {},
	"style": {},
	"disableErrorText": false,
	"useDefaultStartAdornment": true,
	"disableOnBlurValidation": false,
};

ZipcodeTextField.propTypes = {
	type: PropTypes.oneOf(["zipcode"]),
	onBlur: PropTypes.func,
	creditCardNumberId: unsupported("zipcode"),
	creditCardNumber: unsupported("zipcode"),
	validation: PropTypes.func,
	showPassword: unsupported("zipcode"),
	delimiter: unsupported("zipcode"),
	keepCharPositions: unsupported("zipcode"),
	placeholderChar: unsupported("zipcode"),
	guide: unsupported("zipcode"),
	showMask: unsupported("zipcode"),
	disableOnBlurValidation: PropTypes.bool,
	errorHelperText: PropTypes.string,
	helperText: PropTypes.string,
	useDefaultStartAdornment: PropTypes.bool,
	useDefaultEndAdornment: unsupported("zipcode"),
	onValidation: PropTypes.func,
	disableErrorText: PropTypes.bool,
	required: PropTypes.bool,
	onChange: PropTypes.func,
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	name: PropTypes.string,
	variant: PropTypes.oneOf(["standard", "filled", "outlined"]),
	inputProps: PropTypes.object,
	InputProps: PropTypes.object,
	style: PropTypes.object,
	groupId: PropTypes.string,
	placeholder: PropTypes.string,
	...TextField.propTypes,
};

export default ZipcodeTextField;
