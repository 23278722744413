import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { compose } from "recompose";
import withStores from "@libs/framework/src/libs/withStores";
//import { stores } from "./.config";
import { observer } from "mobx-react";
import useStores from "@libs/framework/src/hooks/useStores";

const RouterComponent = compose(
	//withStores(stores),
	observer
)(({ isWithinSPA = false, children, url, target = "_self" }) => {
	//const { backgroundStore: store } = useStores();

	const closeNavDrawer = (e) => {
		//store.navState[store.anchor] = false;
	};

	return isWithinSPA ? (
		<RouterLink
			color={"inherit"}
			underline={"none"}
			to={url}
			target={target}
			onClick={closeNavDrawer}
		>
			{children}
		</RouterLink>
	) : (
		<Link color={"inherit"} underline={"none"} href={url} target={target}>
			{children}
		</Link>
	);
});

RouterComponent.propTypes = {
	isWithinSPA: PropTypes.bool,
	children: PropTypes.element.isRequired,
	url: PropTypes.string.isRequired,
	target: PropTypes.oneOf(["_blank", "_self"]),
};
export default RouterComponent;
