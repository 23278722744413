import { observable, toJS } from "mobx";
import required from "@libs/required";

class MobileDrawerStore {
	@observable state = {
		top: false,
		left: false,
		bottom: false,
		right: false,
	};

	isOpen(anchor = required`anchor`) {
		return !!this.state[anchor];
	}

	update(anchor = required`anchor`, val = required`val`) {
		this.state = Object.assign({}, toJS(this.state), { [anchor]: val });
	}
}

export default MobileDrawerStore;
