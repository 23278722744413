import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { compose } from "recompose";

import useStyles from "@hooks/useStyles";
import useStores from "@hooks/useStores";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";

import HeaderSearchDrawer from "@components/Main/Shared/HeaderSearchDrawer";

const HeaderSearchBarMobile = compose(observer)(({ open, anchor }) => {
	const classes = useStyles("HeaderSearchBar");
	const { headerSearchBarStore: store } = useStores();

	useEffect(() => {
		store.toggleDrawer(open);
	}, [open]);

	const onToggleHandler = (open) => {
		store.toggleDrawer(open);
	};

	return (
		<React.Fragment>
			<Box
				component={"div"}
				display={{ xs: "block", sm: "none" }}
				width={"100%"}
				maxWidth={500}
			>
				<Button
					onClick={() => store.toggleDrawer(true)}
					fullWidth
					classes={{ root: classes.searchBorder, label: classes.label }}
					className={classes.searchButtonMobile}
					endIcon={<SearchIcon className={classes.iconZipMobile} />}
				>
					<Box
						component="span"
						className={classes.inputTruncate}
						width={"100%"}
					>
						<Box
							component="span"
							display={"flex"}
							maxWidth={"54vw"}
							textAlign={"left"}
						>
							{store.service ? (
								<div>
									<span className={classes.inputTruncate}>
										{store.service?.name_proper?.ucFirst()}
									</span>
									<Box component="span" style={{ flexShrink: 0 }}>
										&nbsp;• {store.zipcode || "Enter Zipcode"}
									</Box>
								</div>
							) : (
								<div>What’s on your to-do list?</div>
							)}
						</Box>
					</Box>
				</Button>
			</Box>
			<HeaderSearchDrawer
				open={store.drawerDisplay}
				service={store.service}
				onToggle={onToggleHandler}
			/>
		</React.Fragment>
	);
});

HeaderSearchBarMobile.defaultProps = {
	anchor: "top",
};

HeaderSearchBarMobile.propTypes = {
	anchor: PropTypes.oneOf(["top", "left", "bottom", "right"]),
	zipcodeOnChangeHandler: PropTypes.func.isRequired,
	serviceOnSelectHandler: PropTypes.func.isRequired,
	serviceOnChangeHandler: PropTypes.func.isRequired,
	onSubmitHandler: PropTypes.func.isRequired,
};

export default HeaderSearchBarMobile;
